import React, { useEffect, lazy } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { NON_HOME_PATH_ROUTE } from 'celestial/data/constants';

// Required in all bundles
import useRestoreCache from 'data/graphql/cache';
import { persistor as aasPersistor } from 'data/graphql/aas/cache';
import Path from './routes/path';

/* ----------- Layouts ----------- */
const AdHocLayout = lazy(() =>
  import(
    /* webpackChunkName: "AdHocLayout" */
    'components/Layout/AdHocLayout'
  )
);

const CelestialEmbeddedLayout = lazy(() =>
  import(
    /* webpackChunkName: "CelestialEmbeddedLayout" */
    'celestial/layouts/CelestialEmbeddedLayout'
  )
);

const CelestialLayout = lazy(() =>
  import(
    /* webpackChunkName: "CelestialLayout" */
    'celestial/layouts/CelestialLayout'
  )
);

const AuthCallback = lazy(() =>
  import(
    /* webpackChunkName: "CelestialAuthCallback" */
    'components/Layout/Auth/CelestialAuthCallback'
  )
);
const AuthError = lazy(() =>
  import(
    /* webpackChunkName: "CelestialAuthError" */
    'components/Layout/Auth/CelestialAuthError'
  )
);
const AuthLogout = lazy(() =>
  import(
    /* webpackChunkName: "CelestialLogout" */
    'components/Layout/Auth/CelestialLogout'
  )
);

/* ----------- Pages ----------- */

const Story = lazy(() =>
  import(
    /* webpackChunkName: "Story" */
    'celestial/pages/Story'
  )
);

const StorySettings = lazy(() =>
  import(
    /* webpackChunkName: "StorySettings" */
    'celestial/pages/StorySettings'
  )
);

const Browse = lazy(() =>
  import(
    /* webpackChunkName: "Browse" */
    'celestial/pages/Browse'
  )
);
const Brand = lazy(() =>
  import(
    /* webpackChunkName: "Brand" */
    'celestial/pages/Brand'
  )
);

const Analytics = lazy(() =>
  import(
    /* webpackChunkName: "Analytics" */
    'celestial/pages/Analytics'
  )
);

const AnalyticsEmbed = lazy(() =>
  import(
    /* webpackChunkName: "Analytics" */
    'celestial/pages/AnalyticsEmbed'
  )
);

const Earnings = lazy(() =>
  import(
    /* webpackChunkName: "Earnings" */
    'celestial/pages/Earnings'
  )
);

const TermsAndConditions = lazy(() =>
  import(
    /* webpackChunkName: "Analytics" */
    'celestial/pages/terms-and-conditions'
  )
);

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const App = () => {
  // TO BE REMOVED IN CMCREATION-844
  useEffect(() => {
    const favicon = document.getElementById('favicon');
    favicon.href = `${process.env.PUBLIC_URL}/favicon-celestial.png`;
  }, []);
  // END OF CODE TO BE REMOVED IN CMCREATION-844

  useRestoreCache(aasPersistor);
  const router = sentryCreateBrowserRouter([
    /* Auth */
    {
      path: `${process.env.PUBLIC_URL}/auth/callback`,
      element: (
        <Path
          key="path"
          authEnabled={false}
          layout={AuthCallback}
          routeShortName="auth:callback"
          path="auth/callback"
        />
      ),
    },

    {
      path: `${process.env.PUBLIC_URL}/auth/login`,
      element: (
        <Path key="path" path="auth/login" authEnabled="force" layout={AdHocLayout} routeShortName="auth:login" />
      ),
    },
    {
      path: `${process.env.PUBLIC_URL}/auth/logout`,
      element: (
        <Path
          key="path"
          path="auth/logout"
          authEnabled={false}
          logout={true}
          layout={AuthLogout}
          routeShortName="auth:logout"
        />
      ),
    },

    {
      path: `${process.env.PUBLIC_URL}/auth/error/:error`,
      element: (
        <Path
          key="path"
          path="auth/error/:error"
          authEnabled={false}
          error={true}
          layout={AuthError}
          routeShortName="auth:error"
        />
      ),
    },

    /* Story */
    {
      path: `${process.env.PUBLIC_URL}/story/:id`,
      element: (
        <Path key="path" path="story/:id" render={Story} layout={CelestialLayout} routeShortName="y4c:story:edit" />
      ),
    },

    {
      path: `${process.env.PUBLIC_URL}/story/new`,
      element: (
        <Path key="path" render={Story} routeShortName="y4c:story:new" layout={CelestialLayout} path="/story/new" />
      ),
    },

    /* Story Settings */
    {
      path: `${process.env.PUBLIC_URL}/story/:id/settings`,
      element: (
        <Path
          key="path"
          path="story/:id/settings"
          render={StorySettings}
          layout={CelestialLayout}
          routeShortName="y4c:story:settings"
        />
      ),
    },

    /* Stories (Home) */
    {
      path: `${process.env.PUBLIC_URL}/`,
      element: <Path key="path" render={Browse} routeShortName="y4c:browseStories" layout={CelestialLayout} path="/" />,
    },

    /* Brand */
    {
      path: `${NON_HOME_PATH_ROUTE}/profile`,
      element: (
        <Path
          key="path"
          render={Brand}
          routeShortName="y4c:brandSettings"
          layout={CelestialLayout}
          path="/brand"
          isBrandPage
        />
      ),
    },
    {
      path: `${process.env.PUBLIC_URL}/brand`,
      element: (
        <Path
          key="path"
          render={Brand}
          routeShortName="y4c:brandSettings"
          layout={CelestialLayout}
          path="/brand"
          isBrandPage
        />
      ),
    },

    /* Analytics */
    {
      path: `${process.env.PUBLIC_URL}/analytics`,
      element: (
        <Path key="path" render={Analytics} routeShortName="y4c:analytics" layout={CelestialLayout} path="/analytics" />
      ),
    },

    {
      path: `${process.env.PUBLIC_URL}/analytics-embed`,
      element: (
        <Path
          key="path"
          render={AnalyticsEmbed}
          routeShortName="y4c:analyticsEmbed"
          layout={CelestialEmbeddedLayout}
          path="/analytics-embed"
        />
      ),
    },

    /* Earnings */
    {
      path: `${process.env.PUBLIC_URL}/earnings`,
      element: (
        <Path key="path" render={Earnings} routeShortName="y4c:earnings" layout={CelestialLayout} path="/earnings" />
      ),
    },

    /* Terms And Conditions */
    {
      path: `${process.env.PUBLIC_URL}/terms-and-conditions`,
      element: (
        <Path
          key="path"
          render={TermsAndConditions}
          routeShortName="y4c:termsAndConditions"
          layout={CelestialLayout}
          path="/terms-and-conditions"
        />
      ),
    },
  ]);
  return <RouterProvider router={router} />;
};

export default App;
